<template>
    <div class="edit-single">
        <h1>
            Edit project
        </h1>
        {{ currentUser.username }}

        <Spacer :size="20" />

        <form @submit.prevent="create">
            <div class="input-group">
                <input 
                    type="text" 
                    v-model="name"
                    name="name" 
                    id="name"
                    placeholder="Fancy project">
                <label for="name">Project name</label>
            </div>
            <div class="input-group">
                <input
                    type="text"
                    v-model="link"
                    name="link"
                    id="link"
                    placeholder="Link to your project">
                <label for="link">Link</label>
            </div>
            <div class="input-group has-textarea">
                <textarea
                    v-model="description"
                    name="description"
                    id="description"
                    placeholder="Add come details"
                    autocomplete="off"
                    class="resize-textarea"></textarea>
                <label for="description">Description</label>
            </div>

            <Spacer :size="20" />

            <button
                type="submit"
                class="btn primary full-width">
                Create <span v-if="name.length != 0" class="name">"{{ name }}"</span>
            </button>

            <div class="notification-wrapper" v-if="message.text">
                <Spacer :size="20" />
                <InlineNotification 
                    :success="message.success">
                    {{ message.text }}
                </InlineNotification>
            </div>
        </form>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>